import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { LocaleProvider } from "providers/LocaleProvider";
import AuthProvider from "providers/AuthProvider";
import Layout from "containers/Layout";
import Login from "containers/Login";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <LocaleProvider>
        <AuthProvider>
          <Router>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Layout />
          </Router>
        </AuthProvider>
      </LocaleProvider>
    </ChakraProvider>
  );
}

export default App;
