import Filters from "components/Filters";
import useFilters from "components/Filters/useFilters";
import FormInput from "components/FormInput";
import PaginatedTable from "components/PaginatedTable";
import usePagination from "components/PaginatedTable/usePagination";
import useSelect from "components/PaginatedTable/useSelect";
import useSort from "components/PaginatedTable/useSort";
import React, { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { get } from "api/index";
import { useToast } from "@chakra-ui/react";

const UsersManagement: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(1);
  const select = useSelect(setItems);
  const pagination = usePagination();
  const sort = useSort();
  const { filters, onChange, applyFilters, clearFilters } = useFilters([
    "name",
    "email",
    "from",
    "to",
  ]);
  const { user } = useAuthContext();
  const history = useHistory();
  const toast = useToast();
  // current page
  const [current, setCurrent] = useState<number>();
  //callback to fetchUsers
  const fetchUsers = useCallback(
    (queries?: any) => {
      const queryParams = queries
        ? "&" +
          Object.entries(queries)
            .map(([key, value], index, arr) =>
              key ? `${key}=${value}${index < arr.length - 1 ? "&" : ""}` : ""
            )
            .join("")
        : "";
      const URL = `user?page=${pagination.activePage}&current=${pagination.perPage}${queryParams}`;
      get(URL, undefined, user?.accessToken)
        .then((res: any) => {
          if (!Array.isArray(res.result)) {
            toast({
              title: "Failed to load data..",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            return;
          }
          if (res.result.length === 0) {
            toast({
              title: "No users found ...",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            return;
          }
          //Customize the response to adapt to display fields
          const resG: any[] = res.result.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
              email: item.email,
              phone: item.phone ? item.phone : "",
              country: item.country ? item.country.name : "",
              nationalityProofUrl: item.nationalityProofUrl,
              proveUrl: item.activeSubscription?.order?.proveUrl || "",
              activeSubscription: item.activeSubscription,
              paymentMethod:
                item.subscriptions.length &&
                item.subscriptions[item.subscriptions.length - 1].order
                  ? item.subscriptions[item.subscriptions.length - 1].order
                      .paymentMethod
                  : "",
              subscriptionActivationDate: item.subscriptions[
                item.subscriptions.length - 1
              ]
                ? new Date(
                    item.subscriptions[item.subscriptions.length - 1].startDate
                  )
                    .toJSON()
                    .slice(0, 10)
                    .split("-")
                    .reverse()
                    .join("/")
                : "",
              period: item.subscriptions[item.subscriptions.length - 1]
                ? `${
                    new Date(
                      item.subscriptions[item.subscriptions.length - 1].endDate
                    ).getFullYear() -
                    new Date(
                      item.subscriptions[
                        item.subscriptions.length - 1
                      ].startDate
                    ).getFullYear()
                  } Year(s)`
                : "",
            };
            // resG.push(itemG);
          });
          setTotal(Math.ceil(res.count / pagination.perPage));
          setItems(resG);
        })
        .catch(() => {
          toast({
            title: "Failed to load data..",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    },
    [pagination.activePage, pagination.perPage, toast, user?.accessToken]
  );
  // just to make sure branch updated
  useEffect(() => {
    fetchUsers(filters);
  }, [
    pagination.activePage,
    pagination.perPage,
    current,
    filters,
    fetchUsers,
    history,
  ]);

  //callback to execute when applying filters
  const filterUsers = useCallback(() => {
    fetchUsers(filters);
  }, [filters, fetchUsers]);

  useEffect(() => {
    let unlisten = history.listen(filterUsers);
    return unlisten;
  }, [filterUsers, history]);

  if (!user) return <Redirect to="/login" />;

  return (
    <>
      <Filters apply={applyFilters} clear={clearFilters}>
        <FormInput
          name="name"
          value={filters["name"] || ""}
          onChange={onChange}
          label="Name"
        />
        <FormInput
          name="email"
          value={filters["email"] || ""}
          onChange={onChange}
          label="Email"
        />

        <FormInput
          type="date"
          name="from"
          value={filters["from"] || ""}
          onChange={onChange}
          label="From"
        />

        <FormInput
          type="date"
          name="to"
          value={filters["to"] || ""}
          onChange={onChange}
          label="To"
        />
      </Filters>
      {/* <ValidateUser validationEmail={validationEmail}  >
        <FormInput
          name="email"
          label="Email"
          placeholder="xyz@mail.com"
          value={validationEmail}
          onChange={(e:any)=>setValidationEmail(e.target.value)}
        />
      </ValidateUser> */}
      <PaginatedTable
        {...select}
        {...pagination}
        {...sort}
        fields={[
          "name",
          "email",
          "phone",
          { key: "proveUrl", label: "Payment Proof" },
          { key: "nationalityProofUrl", label: "Nationality Proof" },
          "country",
          "subscriptionActivationDate",
          "paymentMethod",
          "period",
          "actions",
        ]}
        //actions={actions}

        items={items}
        loading={true}
        user={user}
        setCurrent={setCurrent}
        total={total}
      />
    </>
  );
};

export default UsersManagement;
