import React, { useState, FormEvent, useContext } from "react";
import { CForm, CInput, CButton } from "@coreui/react";
import { BASE_URL } from "api/index";
import { AuthContext } from "providers/AuthProvider/AuthProvider";
import { Redirect } from "react-router-dom";
import { Box } from "@chakra-ui/react";
//styles for the form
const formStyles = {
  background: "#fff",
  padding: "30px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
};

const Login = () => {
  //statuses
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  //current user and login method
  const { user, login } = useContext(AuthContext);

  //login data tracking
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  //method for handling input change
  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setLoginData({ ...loginData, [target.name]: target.value });
  };

  //method for submitting login data
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    //if still awaiting response from backend ignore
    if (processing) return;
    setProcessing(true);
    //wait for the response
    const response = await fetch(`${BASE_URL}auth/login`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(loginData),
    });
    //get the json data
    const json = await response.json();
    setProcessing(false);
    //check for it's validity
    if (json && json.accessToken) {
      login(json);
    } else if (json && json.message) {
      setError(json.message);
    } else {
      setError("Something went wrong..");
    }
  };

  if (user) return <Redirect to="/dashboard" />;

  return (
    <Box>
      <CForm
        style={formStyles}
        onSubmit={handleSubmit}
        className="login-form position-fixed"
      >
        <div className="my-3 pb-2 text-center font-weight-bold">
          <h3 style={{ fontSize: "26px" }}>SAL - Admin</h3>
        </div>
        {error ? (
          <div className="font-weight-bold text-center text-danger">
            <p>{error}</p>
          </div>
        ) : null}
        <div className="my-3">
          <CInput
            name="email"
            type="email"
            required
            placeholder="Email"
            value={loginData.email}
            onChange={handleChange}
          />
        </div>
        <div className="my-3">
          <CInput
            name="password"
            type="password"
            required
            placeholder="Password"
            value={loginData.password}
            onChange={handleChange}
          />
        </div>
        <div className="mt-4 text-center">
          <CButton type="submit" className="bg-success" disabled={processing}>
            Login
          </CButton>
        </div>
      </CForm>
    </Box>
  );
};

export default Login;
