import { useState } from "react";

const useSidebarState = () => {
  const [showSidebar, setShowSidebar] = useState<boolean | "" | "responsive">(
    "responsive"
  );

  const toggleSidebar = () =>
    setShowSidebar(
      [true, "responsive"].includes(showSidebar) ? false : "responsive"
    );
  const toggleSidebarMobile = () =>
    setShowSidebar(
      [false, "responsive"].includes(showSidebar) ? true : "responsive"
    );
  return { toggleSidebarMobile, toggleSidebar, showSidebar };
};
export default useSidebarState;
