const apiEnv: any = {
  development: {
    baseUrl: "https://sal-api.testing.techhive.dev/admin/",
    endPoint: "https://sal-api.testing.techhive.dev/",
    mediaUrl: "https://saladmin.smartsway.com:3000",
    mediaEndPoint: "https://saladmin.smartsway.com:3000/media",
  },
  production: {
    baseUrl: "https://salapi.smartsway.com/admin/",
    endPoint: "https://salapi.smartsway.com/",
    mediaUrl: "https://saladmin.smartsway.com:3000",
    mediaEndPoint: "https://saladmin.smartsway.com:3000/media",
  },
};
//https://sal-api.new-sal.techhive.dev/
// -----------------------------------------------------------------------------

const appEnv = process.env.REACT_APP_ENV || "development";
export let BASE_URL: any = apiEnv[appEnv].baseUrl;
export let APIEndpoint: any = apiEnv[appEnv].endPoint;
export let MEDIA_URL: any = apiEnv[appEnv].mediaUrl;
let MediaAPIEndpoint: any = apiEnv[appEnv].mediaEndPoint;

// -----------------------------------------------------------------------------

const currentOrigin = window.location.origin; // Get the current origin

export const get = <T>(
  endpoint: string,
  locale?: string,
  auth_token: string = "invalid"
): Promise<T> =>
  fetch(BASE_URL + endpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": locale || "ar",
      origin: currentOrigin,
    },
  })
    .then(handleRes)
    .catch(() => null);

// -----------------------------------------------------------------------------

export const getHealing = <T>(
  locale?: string,
  auth_token: string = "invalid"
): Promise<T> =>
  fetch(`${APIEndpoint}/healing`, {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": locale || "ar",
      origin: currentOrigin,
    },
  })
    .then(handleRes)
    .catch(() => null);

// -----------------------------------------------------------------------------

export const post = <T>(
  endpoint: string,
  body: T,
  locale?: string,
  method: string = "POST",
  auth_token: string = "invalid"
): Promise<T> =>
  fetch(BASE_URL + endpoint, {
    method: method || "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": locale || "ar",
      origin: currentOrigin,
    },

    body: JSON.stringify(body),
  })
    .then(handleRes)
    .catch(() => null);

// -----------------------------------------------------------------------------

export const put = <T>(
  endpoint: string,
  body: T,
  locale?: string,
  auth_token: string = "invalid"
): Promise<T> =>
  fetch(BASE_URL + endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": locale || "ar",
      origin: currentOrigin,
    },

    body: JSON.stringify(body),
  })
    .then(handleRes)
    .catch(() => null);

// -----------------------------------------------------------------------------

export const editPrice = ({
  id,
  price,
  locale,
  auth_token = localStorage.getItem("__auth") || "invalid",
}: {
  id: string;
  price: number;
  locale?: string;
  auth_token?: string;
}) =>
  fetch(BASE_URL + `subscription/order/price/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": locale || "ar",
      origin: currentOrigin,
    },

    body: JSON.stringify({ price }),
  });

// -----------------------------------------------------------------------------

export const deleteMedia = (
  path: string,
  auth_token: string = "invalid"
): Promise<void> =>
  fetch(MediaAPIEndpoint + "/" + path, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": "ar",
      origin: currentOrigin,
    },
  })
    .then(handleRes)
    .catch(() => null);

// -----------------------------------------------------------------------------

export const deleteMethod = (
  endpoint: string,
  auth_token: string = "invalid"
): Promise<void> =>
  fetch(BASE_URL + endpoint, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${auth_token}`,
      "x-localization": "ar",
      origin: currentOrigin,
    },
  })
    .then(handleRes)
    .catch(() => null);

// -----------------------------------------------------------------------------

const handleRes = async (res: Response) => {
  if (res.status === 401 && window.location.pathname !== "/login") {
    window.localStorage.clear();
    window.location.reload();
  }
  try {
    const jsonRes = await res.json();
    if (!res.ok) throw jsonRes;
    return jsonRes;
  } catch (e) {
    return new Promise((resolve) => resolve(res.ok));
  }
};

// -----------------------------------------------------------------------------

export const getAmazonConfig = () => {
  //Update these values before production
  return {
    bucketName: "BUCKET-NAME", //The bucket name you are using to save images
    dirName: "DIR-NAME", //optional, The dir to save the iamge to
    region: "REGION", //The region for AWS eg. eu-west-1
    accessKeyId: "ACCESS-KEY", //Your AWS Access key
    secretAccessKey: "SECRET-KEY", //You AWS Secrey Key
    s3Url: "S3-BUCKET-URL", //optional, Custom S3 Url
  };
};

// -----------------------------------------------------------------------------

export const uploadFile = async (
  file: File,
  auth_token: string = "invalid"
) => {
  //data object to send to server
  const data = new FormData();

  //append the file object to the data
  data.append("file", file);

  //uploading file to the web server
  const response = await fetch(MediaAPIEndpoint, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
    method: "POST",
    body: data,
  });

  //get the json
  const json = await response.json();
  //check the data
  if (json.url) return `${MEDIA_URL}/${json.url}`;
  else {
    alert("Failed to upload file!");
  }
};
