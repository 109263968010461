import { CHeader, CToggler } from "@coreui/react";
import React from "react";

type HeaderProps = {
  toggleSidebar: () => void;
  toggleSidebarMobile: () => void;
};

const Header: React.FC<HeaderProps> = ({
  toggleSidebar,
  toggleSidebarMobile,
}) => {
  return (
    <CHeader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ms-3 d-md-down-none"
        onClick={toggleSidebar}
      />
    </CHeader>
  );
};
export default Header;
