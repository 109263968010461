import { defaultPerPageIndex, perPageOptions } from "config";
import queryString from "query-string";
import { PaginationValue } from "./types";

const getPaginationFromURL = () => {
  const search = window.location.search;
  const initialPaginatiomFromUrl = queryString.parse(search).pagination;
  let initialPagination: PaginationValue = {
    page: 1,
    perPage: perPageOptions[defaultPerPageIndex],
  };
  if (initialPaginatiomFromUrl) {
    const parsedPagination = JSON.parse(initialPaginatiomFromUrl as string);
    if (parsedPagination.page && parsedPagination.perPage)
      initialPagination = parsedPagination;
  }
  return initialPagination;
};
export default getPaginationFromURL;
