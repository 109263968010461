import { CButton, CFormGroup } from "@coreui/react";
import Filters from "components/Filters";
import useFilters from "components/Filters/useFilters";
import FormInput from "components/FormInput";
import PaginatedTable from "components/PaginatedTable";
import usePagination from "components/PaginatedTable/usePagination";
import useSelect from "components/PaginatedTable/useSelect";
import useSort from "components/PaginatedTable/useSort";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";
import { editPrice, get } from "api/index";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { allowedToEditPriceList } from "./allowedToEditPriceList";

const commonFields = [
  "name",
  "country",
  "email",
  "phone",
  "period",
  "paymentMethod",
  "price",
  { key: "proveUrl", label: "Payment Proof" },
  { key: "nationalityProofUrl", label: "Nationality Proof" },
  "date",
  "activationDate",
  "status",
  "respondedBy",
];
const actionFields = ["accept", "reject"];
const editPriceField = [{ key: "editPrice", label: "Edit price" }];

const OrdersManagement = () => {
  const [currentPrice, setCurrentPrice] = useState<number | undefined>(0);
  const [selected, setSelected] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [items, setItems] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(1);
  const select = useSelect(setItems);
  const pagination = usePagination();
  const sort = useSort();
  const { filters, onChange, applyFilters, clearFilters } = useFilters([
    "name",
    "email",
    "from",
    "to",
  ]);
  const history = useHistory();
  //last resolved order
  const [current, setCurrent] = useState<number>();
  const { user } = useAuthContext();
  const toast = useToast();

  //method for fetching orders
  const fetchOrders = useCallback(
    async (queries?: any) => {
      const queryParams = queries
        ? "&" +
          Object.entries(queries)
            .map(([key, value], index, arr) =>
              key ? `${key}=${value}${index < arr.length - 1 ? "&" : ""}` : ""
            )
            .join("")
        : "";
      const URL = `subscription/order?page=${pagination.activePage}&current=${pagination.perPage}${queryParams}`;
      const response = await get<any>(URL, undefined, user?.accessToken);
      if (response && Array.isArray(response.result)) {
        const responseG: Array<any> = [];
        response.result.forEach((item: any) => {
          const itemG = {
            id: item.id,
            name: item.user.name,
            phone: item.user.phone || "",
            email: item.user.email,
            nationalityProofUrl: item.user.nationalityProofUrl,
            period: `${item.quotation?.duration} Year(s)`,
            price: item.price ?? item.quotation.price,
            date: new Date(item.date)
              .toJSON()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("/"),
            activationDate: item.subscription?.startDate
              ? new Date(item.subscription.startDate)
                  .toJSON()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")
              : "",
            respondedBy: item.respondedBy ? item.respondedBy.name : "",
            paymentMethod: item.paymentMethod,
            status: item.status,
            country: item.user.country?.name || "",
            proveUrl: item.proveUrl,
          };
          responseG.push(itemG);
        });
        setTotal(Math.ceil(response.count / pagination.perPage));
        setItems(responseG);
      } else {
        toast({
          title: "Failed to load data..",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [pagination.activePage, pagination.perPage, user?.accessToken, toast]
  );
  const allowedToEditPrice = user?.email
    ? allowedToEditPriceList.includes(user.email.toLowerCase())
    : user?.roleId === 2 || user?.role?.name.toLowerCase() === "admin";
  const fields =
    user?.role?.name.toLowerCase() === "accountant"
      ? commonFields.concat(allowedToEditPrice ? editPriceField : [])
      : commonFields
          .concat(actionFields)
          .concat(allowedToEditPrice ? editPriceField : []);

  useEffect(() => {
    fetchOrders(filters);
  }, [
    pagination.activePage,
    pagination.perPage,
    current,
    filters,
    fetchOrders,
  ]);

  //callback to execute when applying filters
  const filterOrders = useCallback(() => {
    fetchOrders(filters);
  }, [filters, fetchOrders]);

  //this adds an event listening to changes in URL to apply filters
  useEffect(() => {
    const unlisten = history.listen(filterOrders);
    return unlisten;
  }, [filterOrders, history]);

  if (
    !user ||
    !["Admin", "Reviewer", "Support user", "Accountant"].includes(
      user.role.name
    )
  )
    return <Redirect to="/login" />;

  return (
    <>
      <Filters apply={applyFilters} clear={clearFilters}>
        <FormInput
          name="name"
          value={filters["name"] || ""}
          onChange={onChange}
          label="Name"
        />
        <FormInput
          name="email"
          value={filters["email"] || ""}
          onChange={onChange}
          label="Email"
        />

        <FormInput
          type="date"
          name="from"
          value={filters["from"] || ""}
          onChange={onChange}
          label="From"
        />
        <FormInput
          type="date"
          name="to"
          value={filters["to"] || ""}
          onChange={onChange}
          label="To"
        />
      </Filters>
      <PaginatedTable
        {...select}
        {...pagination}
        {...sort}
        fields={fields}
        scopedSlots={{
          editPrice: (item: any) => (
            <td>
              <CFormGroup variant="checkbox" className="checkbox">
                <CButton
                  color="info"
                  onClick={() => {
                    setSelected(item);
                    setCurrentPrice(item.price);
                    onOpen();
                  }}
                >
                  Edit price
                </CButton>
              </CFormGroup>
            </td>
          ),
        }}
        //actions={actions}
        items={items}
        loading={true}
        user={user}
        setCurrent={setCurrent}
        total={total}
      />
      <Modal
        onClose={() => {
          setSelected(undefined);
          setCurrentPrice(undefined);
          onClose();
        }}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit price</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type={"number"}
              value={currentPrice}
              onChange={(e) => setCurrentPrice(e.target.valueAsNumber)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                if (currentPrice !== undefined && !isNaN(currentPrice)) {
                  editPrice({
                    id: selected.id,
                    price: currentPrice,
                  })
                    .then(() => {
                      onClose();
                      setSelected(undefined);
                      setCurrentPrice(undefined);
                      toast({
                        title: "Price updated successfully",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                      });
                      fetchOrders();
                      // fetchOrders(filters);
                    })
                    .catch((e) => alert(e));
                }
              }}
              marginRight={3}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                onClose();
                setSelected(undefined);
                setCurrentPrice(undefined);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrdersManagement;
