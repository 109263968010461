import { CButton } from "@coreui/react";

const CreateButton = () => {
  return (
    <CButton className="btn btn-success" type="submit">
      Create
    </CButton>
  );
};
export default CreateButton;
