import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: ["small", false, "large", "huge"] }, { color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      ["clean"],
      [{ direction: "rtl" }], // this is rtl support
    ],
  },
  clipboard: { matchVisual: false },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "size",
  "color",
  "list",
  "bullet",
  "indent",
  "link",
  "align",
];

function QuillTextEditor({ content, updateContent, updateText }: any) {
  return (
    //@ts-ignore
    <ReactQuill
      value={content}
      theme="snow"
      placeholder="write some description here"
      onChange={(content, delta, source, editor) => {
        updateContent(content);
        updateText(editor.getText());
      }}
      modules={modules}
      formats={formats}
    />
  );
}

export default QuillTextEditor;
