import React from "react";
import { CMSModel } from "./CMSModels";
import { CSelect } from "@coreui/react";
import { post } from "api/index";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";
import { useToast } from "@chakra-ui/react";

interface ModelCategoryProps {
  item: CMSModel;
  deleteEndpoint: string;
  categories?: any;
  updateCategoryEndpoint?: string;
  refetchRecords: () => void;
  populated?: boolean;
}

const ModelCategory: React.FC<ModelCategoryProps> = ({
  item,
  refetchRecords,
  categories,
  updateCategoryEndpoint,
}) => {
  const toast = useToast();
  const { user } = useAuthContext();

  const updateItemCategory = async (itemId: any, categoryId: any) => {
    //replace the placeholder id with the id of the item
    const endPoint: any = updateCategoryEndpoint?.replace(
      "{id}",
      `${item.id!}`
    );
    const response: any = await post(
      endPoint,
      { categoryId },
      undefined,
      undefined,
      user?.accessToken
    ).catch(() => {
      toast({
        title: "Couldn't update category of this record..",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    });
    if (response && response.id) {
      toast({
        title: `Successfully updated category of ${response.slug}`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      refetchRecords();
    }
  };
  const handleSelectChange = (e: any) => {
    updateItemCategory(item.id, e.target.value);
  };
  return (
    <td>
      <CSelect
        className="form-select"
        aria-label="Default select example"
        onChange={(e) => {
          handleSelectChange(e);
        }}
      >
        <option selected={item.category === null} value={"0"}>
          Select Category
        </option>
        {categories.map((category: any, idx: any) => {
          return (
            <option // @ts-ignore
              selected={item?.category?.id === category.id}
              key={idx}
              value={category.id}
            >
              {category.slug}
            </option>
          );
        })}
      </CSelect>
    </td>
  );
};

export default ModelCategory;
