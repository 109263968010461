import { CMSModels } from "views/dashboard/ModelManagement/CMSModels";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";

const CMSModelsNav = Object.keys(CMSModels).map((key) => {
  let newKey;
  if (key.includes("_")) {
    newKey = key.replace("_", " ");
  }
  return {
    _tag: "CSidebarNavItem",
    name: newKey ? newKey : key,
    to: `/cms/${key}`,
  };
});
const useNav = () => {
  const { user } = useAuthContext();

  const getNavAccordingToRole = () => {
    //Admin restrictions (none)
    if (user?.role.name === "Admin") {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
        {
          _tag: "CSidebarNavItem",
          name: "Users Feedback",
          to: "/usersFeedback",
        },
        { _tag: "CSidebarNavItem", name: "Orders", to: "/orders" },
        { _tag: "CSidebarNavDropdown", name: "CMS", _children: CMSModelsNav },
      ];
    }
    //Accountant restrictions
    else if (user?.role.name === "Accountant") {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
        { _tag: "CSidebarNavItem", name: "Orders", to: "/orders" },
      ];
    }
    //Support user restrictions
    else if (user?.role.name === "Support user") {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
        {
          _tag: "CSidebarNavItem",
          name: "Users Feedback",
          to: "/usersFeedback",
        },
        { _tag: "CSidebarNavItem", name: "Orders", to: "/orders" },
      ];
    }
    // Data manager restrictions
    else if (user?.role.name === "Data manager") {
      return [
        { _tag: "CSidebarNavDropdown", name: "CMS", _children: CMSModelsNav },
      ];
    }
    //Reviwer restrictions
    else {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
        { _tag: "CSidebarNavItem", name: "Orders", to: "/orders" },
      ];
    }
  };

  return getNavAccordingToRole();
};

export default useNav;
