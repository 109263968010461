import React, { FormEvent, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CDataTable,
  CFormGroup,
  CInputCheckbox,
  CLink,
  CModal,
  CPagination,
  CSelect,
  CLabel,
  CInput,
  CModalHeader,
  CModalBody,
  CModalTitle,
} from "@coreui/react";
import {
  Box,
  useToast,
  HStack,
  useDisclosure,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
} from "@chakra-ui/react";
import { FaImage } from "react-icons/fa";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { perPageOptions } from "config";
import { deleteMethod, put, MEDIA_URL } from "api";
import { PaginatedTableProps, SortValue } from "./types";

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  fields,
  items,
  perPage,
  activePage,
  pagination,
  select,
  unselect,
  selected,
  setPerPage,
  setActivePage,
  actions,
  sortBy,
  sortValue,
  user,
  setCurrent,
  total,
  scopedSlots = {},
}) => {
  const location = useLocation();

  //method for accepting and rejecting orders
  const resolveOrder = async (id: number, status: boolean) => {
    put(`subscription/order/${id}`, { status }, undefined, user?.accessToken)
      .then((res: any) => {
        if (res && res.id) {
          toast({
            title: "Resolved successfully!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
        setCurrent && setCurrent(id);
      })
      .catch(() => {
        toast({
          title: "Something went wrong..",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  };
  //control the visibility for the edit modal
  const [modalInfo, setModalInfo] = useState<any>({
    item: {},
    visible: false,
    itemId: -1,
  });

  //toasting
  const toast = useToast();

  // new End subscription date
  const [endSubDate, setEndSubDate] = useState("");

  const updateUserSubscription = () => {
    const endpoint = `user/${modalInfo.itemId}/updateSubscription`;
    if (!endSubDate) {
      toast({
        title: "Please select a subscription end date.",
        status: "info",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const body = {
      endDate: endSubDate,
    };

    put(endpoint, body, undefined, user?.accessToken)
      .then((res: any) => {
        toast({
          title: "User's subscribtion duration updated successfully!",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setModalInfo({ ...modalInfo, visible: false });
      })
      .catch(() => () => {
        toast({
          title: "Couldn't change user subscription duration..",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        setModalInfo({ ...modalInfo, visible: false });
      });
  };

  const cancelSubscription = async (activeSubscription: any) => {
    const endpoint = `subscription/${activeSubscription.id}`;
    await deleteMethod(endpoint, user?.accessToken);
    toast({
      title: "Subscription cancelled successfully!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [previewData, setPreviewData] = useState<{
    src: string | undefined;
    alt: string | undefined;
  }>({ src: undefined, alt: undefined });

  const [pdfBlobUrl, setPdfBlobUrl] = useState("");

  const fetchPdfWithToken = async (pdfUrl: string) => {
    const response = await fetch(pdfUrl, {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch PDF");
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    setPdfBlobUrl(blobUrl);
  };

  return (
    <CCard>
      {selected ? (
        <CCollapse show={!isEmpty(selected)}>
          <CCardHeader>
            {actions}
            <small
              style={{
                float: "right",
              }}
            >
              {Object.keys(selected).length} Selected
            </small>
          </CCardHeader>
        </CCollapse>
      ) : (
        <></>
      )}
      <CCardBody>
        {pagination ? (
          <CSelect
            style={{ width: 200, marginInlineStart: "auto" }}
            className="my-2"
            value={perPage}
            onChange={(e) => setPerPage?.(e.currentTarget.value)}
          >
            {perPageOptions.map((p, idx) => (
              <option key={idx} value={p}>
                {p}
              </option>
            ))}
          </CSelect>
        ) : null}
        <CDataTable
          fields={fields}
          scopedSlots={{
            ...scopedSlots,
            _select: (item: any) => (
              <td>
                <CFormGroup variant="checkbox" className="checkbox">
                  <CInputCheckbox
                    checked={item._selected || false}
                    onChange={(e: any) => {
                      e.target.checked
                        ? select?.(item.id)
                        : unselect?.(item.id);
                    }}
                  />
                </CFormGroup>
              </td>
            ),
            proveUrl: (item: any) => (
              <td>
                <IconButton
                  aria-label="payment-proof"
                  icon={<FaImage size={28} color="#2a94f7" />}
                  onClick={() => {
                    if (item.proveUrl) {
                      setPreviewData({
                        src: `${MEDIA_URL}/${item.proveUrl}`,
                        alt: "payment-proof",
                      });

                      console.log("previewData.src", item.proveUrl);
                      if (item.proveUrl?.endsWith(".pdf")) {
                        fetchPdfWithToken(`${MEDIA_URL}/${item.proveUrl}`);
                      }
                    } else
                      toast({
                        title: "Empty",
                        description: "No payment proof available.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                      });
                    onOpen();
                  }}
                  variant="ghost"
                  size={"sm"}
                />
              </td>
            ),
            nationalityProofUrl: (item: any) => (
              <td>
                <IconButton
                  aria-label="nationality-proof"
                  icon={<FaImage size={28} color="#2a94f7" />}
                  onClick={() => {
                    if (item.nationalityProofUrl)
                      setPreviewData({
                        src: `${MEDIA_URL}/${item.nationalityProofUrl}`,
                        alt: "nationality-proof",
                      });
                    else
                      toast({
                        title: "Empty",
                        description: "No nationality proof available.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                      });
                    onOpen();
                  }}
                  variant="ghost"
                  size={"sm"}
                />
              </td>
            ),
            _edit: (item: any) => (
              <td>
                <CLink
                  color="primary"
                  // className="btn btn-primary btn-sm"
                  to={`${location.pathname}/${item.id}/edit`}
                >
                  Edit
                </CLink>
              </td>
            ),
            accept: (item: any) => (
              <td>
                {user?.role.name !== "Support user" && (
                  <CButton
                    color="success"
                    onClick={
                      item.status === "Pending"
                        ? () => resolveOrder(item.id, true)
                        : undefined
                    }
                    disabled={item.status !== "Pending"}
                  >
                    Accept
                  </CButton>
                )}
              </td>
            ),
            reject: (item: any) => (
              <td>
                {user?.role.name !== "Support user" && (
                  <CButton
                    color="danger"
                    onClick={
                      item.status === "Pending"
                        ? () => resolveOrder(item.id, false)
                        : undefined
                    }
                    disabled={item.status !== "Pending"}
                  >
                    Reject
                  </CButton>
                )}
              </td>
            ),
            actions: (item: any) => (
              <td>
                <HStack>
                  {user?.role.name &&
                    ["Admin", "Reviewer"].includes(user.role.name) && (
                      <CButton
                        color="primary"
                        style={{ minWidth: "5.5rem", minHeight: "3.5rem" }}
                        onClick={() => {
                          item.activeSubscription.endDate &&
                            setEndSubDate(
                              new Date(item.activeSubscription.endDate)
                                .toJSON()
                                .slice(0, 10)
                            );
                          setModalInfo({
                            item,
                            itemId: item.id,
                            visible: true,
                          });
                        }}
                      >
                        Edit
                      </CButton>
                    )}
                  {(user?.role.name === "Admin" ||
                    user?.role.name === "Accountant" ||
                    user?.role.name === "Reviewer") && (
                    <CButton
                      color="danger"
                      style={{ minWidth: "6.35rem" }}
                      onClick={
                        item.activeSubscription
                          ? () => cancelSubscription(item.activeSubscription)
                          : undefined
                      }
                      disabled={
                        !item.activeSubscription ||
                        !("id" in item.activeSubscription)
                      }
                    >
                      Cancel subscription
                    </CButton>
                  )}
                </HStack>
              </td>
            ),
          }}
          sorter={{ external: true }}
          sorterValue={sortValue}
          onSorterValueChange={(sortValue: SortValue) => sortBy?.(sortValue)}
          items={items}
        />
        {modalInfo.visible && (
          <CModal
            show={modalInfo.visible}
            onClose={() => setModalInfo({ ...modalInfo, visible: false })}
            style={{
              padding: "20px 10px",
              maxHeight: "500px",
              overflowY: "scroll",
            }}
            className="scrollable"
          >
            <CModalHeader closeButton>
              <CModalTitle style={{ fontWeight: 900 }}>
                Edit User Info
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <Box display="block" mb="5">
                <p>
                  Name: <b>{modalInfo.item.name}</b>
                </p>
                <p>
                  Email: <b>{modalInfo.item.email}</b>
                </p>
              </Box>
              <CLabel>Subscription ends at : </CLabel>
              <Box display="flex" justifyContent="space-between" mb="5">
                <CInput
                  style={{ width: "60%" }}
                  type="date"
                  id="newEndSubDate"
                  name="newEndSubDate"
                  value={endSubDate}
                  onChange={(e: FormEvent) => {
                    const target = e.target as HTMLInputElement;
                    setEndSubDate(target.value);
                  }}
                ></CInput>
                <CButton
                  color="success"
                  size={"md"}
                  type={"submit"}
                  onClick={() => {
                    updateUserSubscription();
                  }}
                >
                  Update
                </CButton>
              </Box>
            </CModalBody>
          </CModal>
        )}
        {pagination ? (
          <div className="d-flex justify-content-center">
            <CPagination
              className="my-2"
              pages={total}
              activePage={activePage}
              onActivePageChange={(e: any) => {
                setActivePage?.(e);
              }}
            />
          </div>
        ) : null}
      </CCardBody>
      {previewData.src && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            setPreviewData({ src: undefined, alt: undefined });
            onClose();
          }}
          isCentered
          closeOnEsc
          size={previewData.src.endsWith(".pdf") ? "5xl" : undefined}
        >
          <ModalOverlay />
          <ModalContent backgroundColor="transparent" boxShadow="none">
            {previewData.src.endsWith(".pdf") ? (
              <iframe
                src={pdfBlobUrl}
                width="100%"
                height="650px"
                title="PDF Preview"
              ></iframe>
            ) : (
              <Image
                src={previewData.src}
                alt={previewData.alt}
                loading="eager"
                maxH={"650px"}
                objectFit="contain"
              />
            )}
          </ModalContent>
        </Modal>
      )}
    </CCard>
  );
};
export default PaginatedTable;
