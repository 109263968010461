import React, { useEffect, useMemo } from "react";
import { CDataTable } from "@coreui/react";
import { Center, Spinner, Box } from "@chakra-ui/react";
import { getSlots } from "./DataTable.hooks";
import { DataTableProps } from "./DataTable.type";
import Healing from "./Healing";

const DataTable: React.FC<DataTableProps> = ({
  fields,
  items,
  putEndpoint,
  ...props
}) => {
  const itemsWithWarning = useMemo(
    () =>
      items.map((item) => {
        const newItem = { ...item };
        if (
          item.videoUrl === "" ||
          item.mediaUrl === "" ||
          item.imageUrl === ""
        ) {
          newItem._classes = "table-danger";
        }
        return newItem;
      }),
    [items]
  );
  const [timer, setTimer] = React.useState(false);
  const slots = useMemo(
    () =>
      getSlots({
        putEndpoint,
        ...props,
      }),
    [putEndpoint, props]
  );
  useEffect(() => {
    setTimeout(() => {
      /* for some reason the props are not updated when the component
        is mounted so, we need to wait a bit
        so if the length is more than zero we render the table
        else we render the spinner and wait one second till the
        props are updated if the length is zero then there is no data to render */
      setTimer(true);
    }, 1000);
    return setTimer(false);
  }, [itemsWithWarning.length]);
  const dataProps = useMemo(() => {
    return {
      fields,
      items: itemsWithWarning,
      scopedSlots: slots,
    };
  }, [fields, itemsWithWarning, slots]);
  return (
    <Box className="bg-white p-3" borderRadius="5px" color="#3c4b64">
      {putEndpoint.split("/")[0] === "healing" ? (
        <Healing {...dataProps} />
      ) : itemsWithWarning.length > 0 ? (
        <CDataTable {...dataProps} />
      ) : timer ? (
        <p>No data</p>
      ) : (
        <Center height={"50vh"}>
          <Spinner size={"xl"} />{" "}
        </Center>
      )}
    </Box>
  );
};

export default DataTable;
