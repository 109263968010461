import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";
//@ts-ignore
React.icons = icons;

if (navigator.serviceWorker.controller) {
  const message = window.localStorage.getItem("__sal_auth");
  navigator.serviceWorker.controller.postMessage({
    type: "fromPage",
    message: message,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
