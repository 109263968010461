import { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

const useSelect = (setItems: Dispatch<SetStateAction<any[]>>) => {
  const [selected, setSelected] = useState<any>({});
  const [allSelected, setAllSelected] = useState<boolean>();

  useEffect(() => {
    if (!allSelected) {
      setItems((old) => {
        let newItems: any[] = [];
        old.forEach((oldItem) => {
          newItems.push(
            selected[oldItem.id]
              ? { ...oldItem, _classes: "table-selected", _selected: true }
              : { ...oldItem, _classes: "", _selected: false }
          );
        });
        return newItems;
      });
    } else if (allSelected) {
      setItems((old) => {
        let newItems: any[] = [];
        old.forEach((oldItem) => {
          newItems.push({
            ...oldItem,
            _classes: "table-selected",
            _selected: true,
          });
        });
        return newItems;
      });
    }
    //  else {
    //   setItems((old) => {
    //     let newItems: any[] = [];
    //     old.forEach((oldItem) => {
    //       newItems.push({ ...oldItem, _classes: "", _selected: false });
    //     });
    //     return newItems;
    //   });
    // }
  }, [selected, allSelected, setItems]);

  const reset = () => {
    setSelected({});
    setAllSelected(false);
  };

  const selectAll = () => {
    setSelected({});
    setAllSelected(true);
  };

  const select = (id: string) => setSelected({ ...selected, [id]: true });

  const unselect = (id: string) => {
    const { [id]: omit, ...rest } = selected;
    setSelected(rest);
  };

  return { selected, allSelected, selectAll, reset, select, unselect };
};
export default useSelect;
