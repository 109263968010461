import React, { useState } from "react";
import { IntlProvider } from "react-intl";
type Locale = "ar" | "en";
type LocaleContextProps = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};
export const LocaleContext = React.createContext<LocaleContextProps>({
  locale: "ar",
  setLocale: (locale: Locale) => {},
});

const storedLocale = localStorage.getItem("locale");

export const LocaleProvider: React.FC = ({ children }) => {
  const [locale, setLocaleState] = useState<Locale>(
    storedLocale && ["ar", "en"].includes(storedLocale)
      ? (storedLocale as Locale)
      : "en"
  );

  const setLocale = (locale: Locale) => {
    localStorage.setItem("locale", locale);
    setLocaleState(locale);
  };

  return (
    //@ts-ignore
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {
        //@ts-ignore
        <IntlProvider locale={locale}>{children}</IntlProvider>
      }
    </LocaleContext.Provider>
  );
};
