import { CFormGroup, CInput, CLabel } from "@coreui/react";
import React from "react";

const FormInput: React.FC<CInput> = ({ label, ...props }) => {
  return (
    <CFormGroup>
      <CLabel htmlFor={props.id}>{label}</CLabel>
      <CInput {...props} />
    </CFormGroup>
  );
};
export default FormInput;
