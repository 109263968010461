import queryString from "query-string";
import { SortValue } from "./types";

const getSortFromURL = () => {
  const search = window.location.search;
  const initialSortFromUrl = queryString.parse(search).sort;
  let initialSort: SortValue = {};
  if (initialSortFromUrl) {
    const parsedSort = JSON.parse(initialSortFromUrl as string);
    initialSort = parsedSort;
  }
  return initialSort;
};
export default getSortFromURL;
