import { CContainer, CFade } from "@coreui/react";
import { Route, Switch } from "react-router-dom";
import routes from "routes";
import React from "react";

const Content: React.FC = () => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route key={idx} path={route.path} exact={route.exact}>
                <CFade>
                  <route.component />
                </CFade>
              </Route>
            ) : null;
          })}
        </Switch>
      </CContainer>
    </main>
  );
};
export default React.memo(Content);
