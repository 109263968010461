import { Button } from "@chakra-ui/button";
import { Redirect } from "react-router-dom";
import React from "react";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";
import useNav from "./useNav";

type SidebarProps = {
  show: boolean | "" | "responsive";
};

const Sidebar: React.FC<SidebarProps> = ({ show }) => {
  const { logout } = useAuthContext();
  const _nav = useNav();

  return (
    <CSidebar show={show}>
      <CSidebarNav>
        <CCreateElement
          items={_nav}
          components={{ CSidebarNavItem, CSidebarNavDropdown }}
        />
      </CSidebarNav>
      <Button
        style={{
          color: "#3c4b64",
          width: "100%",
          position: "relative",
          borderRadius: "0px",
          marginBottom: "50px",
        }}
        onClick={() => {
          logout();
          return <Redirect to="/login" />;
        }}
      >
        Log out
      </Button>
    </CSidebar>
  );
};
export default React.memo(Sidebar);
