import React, { createContext, useEffect, useState, useContext } from "react";
import { APIEndpoint } from "api/index";

//type for user Obj
export type user = {
  id: number;
  accessToken: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  roleId: number;
  role: {
    id: number;
    name: string;
  };
};

//context for authentication
export const AuthContext = createContext<{
  user: user | null;
  login: (userObj: user) => void;
  logout: () => void;
}>({ user: null, login: () => {}, logout: () => {} });
export const useAuthContext = () => useContext(AuthContext);
const AuthProvider: React.FC = ({ children }) => {
  //current user object
  const [user, setUser] = useState<user | null>(null);

  //validate user's token once loaded component
  const validateToken = async () => {
    //__auth key in local storage contains email and access token for the user
    const __auth = window.localStorage.getItem("__auth");
    if (__auth) {
      const response = await fetch(`${APIEndpoint}/user/me`, {
        headers: {
          Authorization: `Bearer ${__auth}`,
        },
      });
      const json = await response.json();
      //if the response indicates an admin role and returned the user obj login the user
      if (json?.role?.name === "Admin")
        setUser({ ...json, accessToken: __auth });
    }
  };

  //method for user login
  const login = (userObj: user) => {
    //set the token and the email in the local storage for later verifications
    const __auth = userObj.accessToken;
    window.localStorage.setItem("__auth", __auth);
    //login the user
    setUser(userObj);
  };
  const logout = () => {
    setUser(null);
    window.localStorage.setItem("__auth", "");
  };
  useEffect(() => {
    //determine if it is a refresh first
    const isRefresh = window.localStorage.getItem("rflag");
    if (isRefresh) {
      const expired = new Date().getTime() - +isRefresh > 10 * 1000;
      if (!expired) {
        validateToken();
      }
    }
    //event listener to set the refresh flag
    window.onbeforeunload = () => {
      window.localStorage.setItem("rflag", new Date().getTime().toString());
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
