import UsersManagement from "views/dashboard/UsersManagement";
import UsersFeedback from "views/dashboard/UsersFeedback";
import OrdersManagement from "views/dashboard/OrdersManagement";
import ModelManagement from "views/dashboard/ModelManagement";

const routes: { exact: boolean; component: any; path: string }[] = [
  {
    exact: true,
    component: UsersManagement,
    path: "/users",
  },
  {
    exact: true,
    component: UsersFeedback,
    path: "/usersFeedback",
  },
  {
    exact: true,
    component: OrdersManagement,
    path: "/orders",
  },
  {
    exact: true,
    path: "/cms/:model",
    component: ModelManagement,
  },
];
export default routes;
