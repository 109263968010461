import {
  CDataTable,
  CLabel,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React, { useMemo, useState } from "react";

const margins = { margin: "0 10px 0 10px" };

function getItems({
  items,
  organ,
}: {
  items: Array<any>;
  organ: { female: string; male: string };
}) {
  let maleItems = [];
  let femaleItems = [];
  for (let i in items) {
    const newItem = { ...items[i] };
    newItem.section = newItem.category?.split("-")[1];
    newItem.organ = newItem.category?.split("-")[2];
    const gender = newItem.category?.split("-")[0];
    if (gender === "male" && newItem.section === organ.male) {
      maleItems.push(newItem);
    } else if (gender === "female" && newItem.section === organ.female) {
      femaleItems.push(newItem);
    }
  }
  return { maleItems, femaleItems };
}

export default function Healing({
  fields,
  scopedSlots,
  items,
}: {
  fields: Array<{ key: string; label?: string }>;
  scopedSlots: {};
  items: Array<any>;
}) {
  const [organ, setOrgan] = useState({
    male: "head",
    female: "head",
  });

  const { maleItems, femaleItems } = useMemo(() => {
    return getItems({ items, organ });
  }, [items, organ]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgan({
      ...organ,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <CTabs activeTab="male">
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink data-tab="male">Male</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink data-tab="female">Female</CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane data-tab="male">
          <div
            className={"d-flex align-items-center"}
            style={{
              margin: "10px 0",
            }}
          >
            <CLabel style={margins}>Filter: </CLabel>
            <input
              id="head"
              type="radio"
              value="head"
              name="male"
              onChange={onChange}
              style={margins}
              checked={organ.male === "head"}
            />
            head
            <input
              id="abdomen"
              type="radio"
              value="abdomen"
              name="male"
              onChange={onChange}
              style={margins}
              checked={organ.male === "abdomen"}
            />
            abdomen
            <input
              id="legs "
              type="radio"
              value="limps"
              name="male"
              onChange={onChange}
              style={margins}
              checked={organ.male === "limps"}
            />
            limps
          </div>
          <CDataTable
            fields={fields}
            items={maleItems}
            scopedSlots={scopedSlots}
          ></CDataTable>
        </CTabPane>
        <CTabPane data-tab="female">
          <div
            className={"d-flex align-items-center"}
            style={{
              margin: "10px 0",
            }}
          >
            <CLabel style={margins}>Filter: </CLabel>
            <input
              id="head"
              type="radio"
              value="head"
              name="female"
              onChange={onChange}
              style={margins}
              checked={organ.female === "head"}
            />
            head
            <input
              id="abdomen"
              type="radio"
              value="abdomen"
              name="female"
              onChange={onChange}
              style={margins}
              checked={organ.female === "abdomen"}
            />
            abdomen
            <input
              id="legs"
              type="radio"
              value="limps"
              name="female"
              onChange={onChange}
              style={margins}
              checked={organ.female === "limps"}
            />
            limps
          </div>
          <CDataTable
            fields={fields}
            items={femaleItems}
            scopedSlots={scopedSlots}
          />
        </CTabPane>
      </CTabContent>
    </CTabs>
  );
}
