import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import useFilters from "components/Filters/useFilters";
import PaginatedTable from "components/PaginatedTable";
import usePagination from "components/PaginatedTable/usePagination";
import useSelect from "components/PaginatedTable/useSelect";
import useSort from "components/PaginatedTable/useSort";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";
import { get } from "api/index";
let total = 1;
const UsersFeedback: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const select = useSelect(setItems);
  const pagination = usePagination();
  const sort = useSort();
  const { filters } = useFilters(["name", "email", "from", "to"]);
  const { user } = useAuthContext();
  const history = useHistory();
  const toast = useToast();

  // current page
  const [current, setCurrent] = useState<number>();

  //callback to fetchUsersFeedback
  const fetchUsersFeedback = useCallback(() => {
    get("feedback", undefined, user?.accessToken)
      .then((res: any) => {
        if (!Array.isArray(res)) {
          toast({
            title: "Failed to load data..",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          return;
        }
        if (res.length === 0) {
          toast({
            title: "No Feedback found ...",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          return;
        }
        //Customize the response to adapt to display fields
        const resG: any[] = res.map((item: any) => {
          return {
            // id: item.id,
            name: item.user.name,
            section: item.section,
            message: item.message,
            date: new Date(item.date)
              .toJSON()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("/"),
            // phone: item.phone?item.phone:"",
            // country: item.country ? item.country.name : "",
            // nationalityProofUrl: item.nationalityProofUrl,
            // proveUrl: item.activeSubscription?.order?.proveUrl || "",
            // activeSubscription: item.activeSubscription,
            // paymentMethod: item.subscriptions.length && item.subscriptions[item.subscriptions.length - 1].order ? item.subscriptions[item.subscriptions.length - 1].order.paymentMethod : '',
            // subscriptionActivationDate: item.subscriptions[item.subscriptions.length - 1] ? new Date(item.subscriptions[item.subscriptions.length - 1].startDate).toJSON().slice(0, 10).split('-').reverse().join('/') : "",
            // period: item.subscriptions[item.subscriptions.length - 1] ? `${(new Date(item.subscriptions[item.subscriptions.length - 1].endDate).getFullYear() - new Date(item.subscriptions[item.subscriptions.length - 1].startDate).getFullYear())} Year(s)` : ""
          };
          // resG.push(itemG);
        });
        // setTotal(Math.ceil(res.count / pagination.perPage));
        setItems(resG);
      })
      .catch(() => {
        toast({
          title: "Failed to load data..",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }, [toast, user?.accessToken]);
  // just to make sure branch updated
  useEffect(() => {
    fetchUsersFeedback();
  }, [
    pagination.activePage,
    pagination.perPage,
    current,
    filters,
    fetchUsersFeedback,
  ]);

  //callback to execute when applying filters
  const filterUsers = useCallback(() => {
    fetchUsersFeedback();
  }, [fetchUsersFeedback]);

  useEffect(() => {
    let unlisten = history.listen(filterUsers);
    return unlisten;
  }, [filterUsers, history]);

  if (!user) return <Redirect to="/login" />;

  return (
    <>
      <PaginatedTable
        {...select}
        {...pagination}
        {...sort}
        fields={[
          "name",
          "section",
          "date",
          "message",
          //   {key: "proveUrl", label: "Payment Proof"},
          //   {key: "nationalityProofUrl", label: "Nationality Proof"},
          //   'country',
          //   "subscriptionActivationDate",
          //   'paymentMethod',
          //   "period",
          // "view"
        ]}
        //actions={actions}

        items={items}
        loading={true}
        user={user}
        setCurrent={setCurrent}
        total={total}
      />
    </>
  );
};

export default UsersFeedback;
