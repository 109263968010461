import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CCollapse,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { FormattedMessage } from "react-intl";
import Form from "components/Form";

const Filters: React.FC<{ apply: () => void; clear: () => void }> = ({
  children,
  apply,
  clear,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <CCard>
      <CCollapse show={open}>
        <CCardBody>
          <Form.Block>{children}</Form.Block>
          <CRow>
            <CCol sm={6}>
              <CButton
                type="block"
                block
                color="secondary"
                className="mb-3"
                title="Filter"
                onClick={apply}
              >
                <CIcon name="cilFilter" />
              </CButton>
            </CCol>
            <CCol sm={6}>
              <CButton
                type="block"
                block
                color="danger"
                title="Clear filters"
                onClick={clear}
              >
                <CIcon name="cilFilterX" />
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCollapse>
      <CCardFooter>
        <CButton
          pressed={open}
          active={open}
          onClick={() => setOpen(!open)}
          color="primary"
        >
          <FormattedMessage defaultMessage="Filters" id="filters" />
        </CButton>
      </CCardFooter>
    </CCard>
  );
};
export default Filters;
